@import "variables";

@import "elements";

* {
  margin: 0;
  padding: 0;
}
// html {
// enable dark mode ↓
// color-scheme: light dark;
// overflow-x: hidden;
// }
html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Oxygen, "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Oxygen",
    "Segoe UI", "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: larger;
  color: $base-color-s80;
  a {
    color: inherit;
    text-decoration: none;
  }

  section {
    background-color: $gray-85;
  }
  .pageHero {
    min-height: 60vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .Padding_for_nav {
    padding-top: 100px !important;
  }
  .subSection {
    padding: 5rem 0;
  }
  .subSection:nth-child(even) {
    background-color: $base-color-rgb-20;
  }
  .subSection:nth-child(odd) {
    background-color: $gray-85;
  }
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.colorSecundBase {
  color: $secund-base-color;
}

// not ih use:
.Text_background_image_clip {
  // add image with background
  // background: url("https://res.cloudinary.com/alonfabio/image/upload/v1684409882/photos/Napoleon_hill_1_qvuonp.jpg");
  letter-spacing: -7px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Oxygen", "Arial Narrow", Arial, sans-serif;
  background-clip: text;

  font-weight: 1000;
}
// Tablet
// @media (min-width: 768px) and (max-width: 1024px) {
// }

// Tablet and larger
@media screen and (min-width: 768px) {
  .container {
    display: block;
    max-width: $container-maxW;
    margin: 0 auto;
    padding: 0.625rem;
    transition: width 1s ease-in-out;
  }
}

// Big screen

// @media screen and (min-width: 1024px) {
//   body {
//     .App {
//       .subSection {
//         padding: 10rem 0;
//       }
//     }
//   }
// }

.dark body {
  color: $gray-75;
  .subSection:nth-child(even) {
    background-color: $gray-15;
  }
  .subSection:nth-child(odd) {
    background-color: $gray-24;
  }
  section {
    background-color: $gray-24;
  }
  .PageHero {
    background-color: $base-color-s80;
  }
  // .App .mainContainer .container {
  //   background-color: rgba($base-color-s80, 0.5);
  // }
}
