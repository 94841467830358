@import "../../../styles/scss/";

.modal {
  position: fixed;
  inset: 0;
  z-index: 101;
  opacity: 0;

  .modalContent {
    position: relative;
    background-color: $base-color;
    font-size: 1.25rem;
    border-radius: 1rem;
    padding: 2rem;
    padding-top: 3rem;
    bottom: -100vh;
    animation: down1 2s ease-in-out;
  }
  .modalCloseBtn {
    all: initial;
    font-family: sans-serif, Arial, Helvetica, sans-serif;
    cursor: pointer;
    position: absolute;
    font-size: 1rem;
    color: $secund-base-color;
    background-color: transparent;
    top: 1rem;
    right: 1rem;
  }
}

.modalActive {
  opacity: 1;
  transition: opacity 1s ease, transform 1s ease;
}

@media screen and (min-width: 426px) {
  .modal {
    .modalContent {
      font-size: 3rem;
    }
  }
}

@keyframes down1 {
  0% {
    bottom: 100vh;
  }

  40% {
    bottom: 0vh;
  }

  80% {
    bottom: 0vh;
  }
  100% {
    bottom: -100vh;
  }
}
