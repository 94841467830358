@import "../../styles/scss/variables";

.PDF_section {
  min-height: calc(100vh - 202px - 4rem);

  .PageHero {
    background-color: $base-color;
  }
}
.PDF_h1 {
  font-size: Min(10vw, 5rem);
  text-wrap: nowrap;
  padding-inline: 2rem;
  color: $gray-65;
}
.pdf_a_container {
  position: relative;
  cursor: pointer;
  padding: 2rem;

  span {
    font-size: 70%;
  }
}
.PDF_file_icon {
  position: absolute;
}

.PDF_object_container {
  padding: 0.5rem;
}
.PDF_object {
  width: 100%;
  height: 100vh;
  min-height: 500px;

  border: 0;
}
@media (max-width: 769px) {
  .PDF_section {
    min-height: calc(100vh - 190px - 4rem);
  }
}
@media (min-width: 769px) {
  .PDF_object_container {
    padding-inline: 10vw;
  }
}
