@import "../../styles/scss/variables";

#Contact {
  min-height: calc(100vh - 202px - 4rem);

  // background-color: $base-color-gray-15;
  #formSection {
    min-height: calc(100vh - 264px);
    #formContainer {
      padding: 1rem;
      flex-grow: 3;
      text-align: center;
      .contactForm {
        // background-color: $gray-85;
        border: 1px solid $base-color;
        padding: 1rem;
        #formMessage {
          display: block;
          textarea {
            box-sizing: border-box;
            width: 100%;
            min-height: 10em;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 425px) {
  #Contact {
    min-height: calc(100vh - 264px);
  }
}
// For tablet and larger screen size
@media screen and (min-width: 768px) {
  #Contact {
    min-height: calc(100vh - 264px);

    #formSection {
      display: flex;
      width: 100%;
      // min-height: calc(100vh - 227px);
      background-image: url(../../styles/img/logos/Alons/AlignedB2Large.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 400vh;
      align-items: center;
      padding-bottom: 0;
      #formContainer {
        width: 100%;
        // height: fit-content;
        Form {
          position: relative;
          border-radius: 1rem;
          background-color: $gray-75;

          .formInputs {
            display: flex;
            #IdInputs {
              flex-grow: initial;
            }
            h3 {
              font-size: 1.5rem;
            }
            .formSection {
              align-self: flex-start;
              flex-grow: 1;
              #alignInputs {
                height: 39px;
              }
              .formInline {
                padding: 1rem 0;

                input {
                  padding: 0.25rem;
                  font-size: 1.25rem;
                }
              }

              #formMessage {
                display: flex;
                padding-left: 00.5rem;
                textarea {
                  min-height: 10em;
                  padding: 00.25rem;
                  margin-left: 0.5rem;
                  font-size: 1.25rem;
                }
              }
            }
          }
          #formSubmit {
            position: absolute;
            padding-left: 75%;
            bottom: -30px;
          }
          .error {
            padding: 0 1rem;
          }
        }
      }
    }
  }
}
// Tablet
@media (min-width: 769px) {
  #Contact {
    #Services {
      //Contact Form
      #formSection {
        align-self: flex-start;
        #formContainer {
          form {
            .formInputs {
              #formMessage {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
// Big screen
@media screen and (min-width: 1024px) {
  #Contact {
    min-height: calc(100vh - 264px);

    //Contact Form
    #alignInputs {
      height: 0 !important;
    }
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  #Contact

    //Contact Form
    #formSection {
    align-self: flex-end;
    #formContainer {
      #contactForm {
        .formInputs {
          .formSection {
            #formMessage {
              display: block;
            }
          }
        }
      }
    }
  }
}

.dark body #Contact #formSection #formContainer Form {
  background-color: $gray-15;
  color: $gray-75;
}
