@import "../../styles/scss/variables.scss";

// From full screen to mobile... ↓ Nooby mistake..

#main_nav {
  position: fixed;
  inset: 0 0 auto 0;
  z-index: 99;
  color: $gray-75;
  fill: $gray-75;
  transition: all 0.5s ease-in-out;
  // animation: all 0.5s ease-in-out;
  .navMain {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;

    transition: padding 0.5s ease, background-color 0.5s ease;

    .NavLogo {
      height: 100%;
      cursor: pointer;
    }
    .LogoContainer {
      z-index: 10;
    }
    .navLinksMain {
      padding-inline: 1rem;
      // background-color: inherit;

      .nav_links {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        z-index: 9;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.5rem;
        letter-spacing: 0.05rem;

        transition: background-color 1s ease, top 0.75s ease-out;

        .dark_mode_btn {
          border: 1px solid;
          border-radius: 20px;

          background-color: $gray-35;
          width: 4rem;
          transition: all 1s ease-in-out;
          .dark_mode_icons {
            display: block;
            font-size: 1rem;
            border-radius: 50%;
            border: 1px solid $gray-35;
            width: 1rem;
            height: 1rem;
            padding: 0.25rem;
            box-shadow: 2px 0px 2px $gray-24;
          }
          .fa-sun {
            color: $base-color-s80;
            background-color: $gray-75;
          }
          .fa-moon {
            color: $gray-75;
            background-color: $gray-15;
          }
          span:hover {
            text-shadow: 0px 0px 0px;
            animation: 2s infinite alternate ease-in-out roleIcon;
          }
        }
        li {
          position: relative;
          padding-inline: 0.5rem;
          list-style-type: none;
        }
        .router_links:hover,
        .active {
          cursor: pointer;

          text-shadow: 2px 2px 2px $secund-base-color;
        }
      }
      .nav_li_not_top {
        color: $secund-base-color;
      }
    }
  }
}
#nav_dropdown_ul {
  cursor: pointer;
}

// On tablet and smaller screens:
// Hide the menu
@media screen and (max-width: 768px) {
  #main_nav {
    background-color: $base-color;
    &::before {
      content: " ";
      // position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      top: 0;
      background-color: inherit;
    }
    .navMain {
      border: none;
      #NavLogo {
        fill: inherit;
      }
      .nav_links {
        // For bigger menus subtract from the top
        top: -200px;
        width: 100vw;
        overflow: hidden;
        z-index: -10;
        padding: 1rem 1rem;
        background-color: $base-color;
        color: $secund-base-color;
        position: fixed;
        left: 0;
        display: flex;
        flex-direction: column-reverse;
      }

      .nav_links::before {
        content: "";
        z-index: 10;
        position: fixed;
        inset: 0;
        height: 50px;
        background-color: inherit;
      }
      .navLinksShow {
        top: 50px;
      }
      .burger {
        cursor: pointer;
        z-index: 10;
      }
    }
  }
  // Dropdown menu.

  .nav_dropdown_li {
    border-left: 0.1rem solid $secund-base-color;
    margin-left: 1rem !important;
  }
}

// For tablet and larger screen size
@media screen and (min-width: 768px) {
  #main_nav {
    margin: 0 auto;

    .navMain {
      position: relative;
      max-width: calc(#{$container-maxW} - 6rem);
      margin: 0 auto;
      padding-inline: 2rem;
      background-color: rgba($base-color, 0.5);
      background-clip: padding-box;
      border-radius: 0 0 4rem 4rem/ 0 0 100% 100%;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      &::before {
        content: "";
        opacity: 0.5;
        position: absolute;
        border-radius: 0 0 4rem 4rem/ 0 0 100% 100%;
        display: block;
        background: linear-gradient(
          to top,
          $base-color-rgb-20 0%,
          $base-color-s80 100%
        );
        width: 100%;
        height: 100%;
        top: 0;
        left: -5px;
        z-index: -1;
        padding: 0 5px;
        // padding: 5px;
      }

      .nav_links {
        height: 100%;
        align-items: center;
        color: unset;
        display: flex;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.25rem;
        letter-spacing: 0.05rem;

        li {
          list-style-type: none;
        }
      }
    }
    .NavLogo {
      padding-inline: 1rem;
      fill: inherit;
    }
  }
  .navbar_Top {
    background-color: transparent;

    .LogoContainer:hover {
      filter: drop-shadow(1px 2px 1px lighten($secund-base-color, 10%));
      transition: filter 300ms linear;
    }
  }
  // Dropdown menu.
  .nav_dropdown_li {
    padding: 0.25rem 0.75rem;
    opacity: 0;
    transition: all 0.15s ease-in;
  }

  #nav_dropdown_ul_appear {
    position: absolute !important;

    padding: 0.25rem 0.5rem 0.5rem;
    border-radius: 0 0 15px 15px;
    background-color: inherit;
  }
  #nav_dropdown_ul:hover {
    background-color: rgba($base-color-s80, 0.4);
    .nav_dropdown_li {
      opacity: 1;
    }
  }
}
@media screen and (min-width: 2000px) {
  li {
    font-size: 1.5rem;
  }
}

// Transitions: move form top of page.
#main_nav {
  .nav_Not_Top {
    background-color: $base-color;
    fill: $secund-base-color;
    color: $secund-base-color;
    padding: 1rem calc((100% - #{$container-maxW}) / 2);
    border-radius: unset;
    border: none;
    max-width: unset;
    #nav_dropdown_ul:hover {
      background-color: $base-color;
    }
  }
}

.dark body #main_nav {
  .navMain {
    background-color: rgba($base-color-s80, 0.5);
    color: $gray-75;
    fill: $gray-75;

    .nav_links {
      background-color: inherit;
      .dark_mode_btn {
        background-color: $gray-85;

        padding-left: 2.5rem;
        .dark_mode_icons {
          box-shadow: -2px 0px 2px;
        }
      }
    }
  }
  .nav_Not_Top {
    background-color: $base-color-s80;
    fill: $secund-base-color;
    li {
      color: $secund-base-color;
    }
    &::before {
      background-color: $base-color-s80;
    }
    #nav_dropdown_ul:hover {
      background-color: $base-color-s80;
    }
  }
}
@media screen and (max-width: 768px) {
  #main_nav {
    .nav_Not_Top {
      padding: 0.5rem;
    }
  }
  .dark body #main_nav {
    background-color: $base-color-s80;
    .navMain {
      .nav_links {
        background-color: $base-color-s80;
      }
    }
  }
}

@keyframes roleIcon {
  from {
    rotate: 0;
  }

  to {
    rotate: 45deg;
  }
}

// FIX
// severity: low
// Start in mobile width.
// Scroll down to get the not_top classes
// Enlarge the width
// The logo stays black, needs to be in the same color al links.
