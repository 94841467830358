@import "../../styles/scss/variables";

#Footer {
  min-height: 190px;
  padding: 2rem;
  background-color: $gray-75;
  color: $base-color-s80;
  .container {
    background-color: inherit;
  }
  #footer_links {
    display: flex;
    justify-content: space-between;
    padding-inline-start: 0.5rem;
    flex-wrap: wrap;
    .footer_nav_links {
      display: flex;
      flex-wrap: wrap;

      list-style-type: none;

      .footer_nav_link_ul {
        padding: 0.5rem 1rem;
        padding-left: 0;
        h3 {
          font-family: Oxygen, sans-serif;
          font-weight: 600;
          border-bottom: 1px solid $base-color;
        }
        ul {
          list-style-type: none;
          li {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }
    }
  }
  // Contact Form
  // #footerForm {
  //   padding-top: 1rem;
  //   display: block;
  //   text-align: center;
  //   #contactForm {
  //     padding: unset;
  //     #formMessage {
  //       display: block;
  //       textarea {
  //         box-sizing: border-box;
  //         width: 100%;
  //         min-height: 10em;
  //       }
  //     }
  //     button {
  //       background-color: $secund-base-color;
  //       color: inherit;
  //     }
  //   }
  // }
  .closed {
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transform: translateY(0%);
    transition: all 700ms ease-out;
    padding: 0 !important;
    margin: 0 !important;
  }
  .opened {
    overflow: hidden;
    opacity: 1;
    max-height: 1000px;
    transition: all 700ms ease-in;
  }

  #footer_links {
    button {
      background-color: inherit;
      // padding: unset;
      display: block;
      color: inherit;
      font-size: 1rem;
      font-weight: bolder;
      transition: all 200ms ease;
    }
  }
  #FooterBottom {
    justify-content: space-between;
    padding: 0.5rem;
    #footerIconDiv {
      display: flex;
      flex-wrap: wrap;

      .FooterLinkIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        // font-size: 1.25rem;
        aspect-ratio: 1/1;
        margin: 0 0.125rem;
        width: 3rem;
        border-radius: 50%;
        background-color: $secund-base-color;
        a {
          // A white with a bit of the base color.
          color: $gray-75;
        }
        i {
          font-weight: 900;
        }
      }
    }
    p {
      display: flex;
      align-items: center;
      font-weight: bolder;
      height: 2.5rem;
      padding: 0 0.5rem;
    }
  }
  .service_mark {
    color: inherit;
  }
}

// For tablet and larger screen size
@media screen and (min-width: 768px) {
  #Footer {
    hr {
      margin: 1rem 5rem;
    }
    #footer_links {
      justify-content: space-between;
      button {
        font-size: 1.25rem;
      }
    }
    // Connect Form

    #footerForm {
      //   width: 100%;
      //   padding-bottom: 2rem;
      //   #contactForm {
      //     padding: 2rem;
      //   }
      .contactForm {
        padding: 1rem;
        //     position: relative;
        //     border-radius: 1rem;
        //     background-color: $gray-75;

        //     .formInputs {
        //       display: flex;
        //       #IdInputs {
        //         flex-grow: initial;
        //       }
        //       h3 {
        //         font-size: 1.5rem;
        //       }
        //       .formSection {
        //         align-self: flex-start;
        //         flex-grow: 1;
        //         #alignInputs {
        //           height: 39px;
        //         }
        //         .formInline {
        //           padding: 1rem 0;

        //           input {
        //             padding: 0.25rem;
        //             font-size: 1.25rem;
        //           }
        //         }

        //         #formMessage {
        //           display: flex;
        //           // flex-direction: row;
        //           padding-left: 00.5rem;

        //           textarea {
        //             min-height: 10em;
        //             padding: 00.25rem;
        //             // margin-left: 0.5rem;
        //             font-size: 1.25rem;
        //           }
        //         }
        //       }
        //     }
        //     #formSubmit {
        //       position: absolute;
        //       padding-left: 75%;
        //       bottom: -30px;
        //     }
        //     .error {
        //       padding: 0 1rem;
        //     }
      }
    }
    .opened {
      max-height: 500px;
    }
  }
}

// Big screen
@media screen and (min-width: 1024px) {
  #Footer {
    .container {
      margin: 0 auto;
      max-width: 1110px;
    }
  }
}

// @keyframes fadeOut {
//   0% {
//     height: 0%;
//   }
//   100% {
//     height: 100%;
//   }
// }

// .fading {
//   animation-name: fadeOut;
//   animation-duration: 0.3s;
//   animation-direction: normal;
//   will-change: height;
// }

.dark body #Footer {
  background-color: $gray-15;
  color: $gray-75;

  #FooterBottom {
    #footerIconDiv {
      .FooterLinkIcon {
        i {
          color: $gray-15;
        }
      }
    }
  }
}
