@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../../styles/scss/variables";

#ImageGalleryContainer {
  //   position: relative;
  //   display: flex;
  //   flex-direction: column;
  //   align-self: center;
  //   height: 100dvh;
  // margin: auto 0;
  // display: block;
  // background-color: rgba(#fff, 0.8);
  // padding: 0 1em;
  width: 100vw;
  .image-gallery {
    .image-gallery-image {
      max-height: calc(100vh - 160px);
    }

    .exit_gallery {
      cursor: pointer;
      background-color: transparent;
      font-size: 1.5rem;
      position: fixed;
      top: 1em;
      left: 1em;
      border: none;
      z-index: 4;
      &:hover {
        text-shadow: #000000 1px 3px 5px;
      }
      transition: text-shadow 0.25s;
    }
    .image-gallery-icon {
      color: $secund-base-color;
    }
    .image-gallery-thumbnails-container {
      .image-gallery-thumbnail {
        &:hover {
          border-color: $secund-base-color;
        }
      }
      .active {
        border-color: $base-color;
      }
    }
  }
}
.modal_container {
  display: flex;
  // position: absolute;
  // inset: 0;
  // width: 100vw;
  // height: 100vh;
  align-items: center;
  height: 100%;

  .gallery_model {
    background-color: rgba(#000000, 0.5);
    transition: opacity 0.4s ease, visibility 0.4s ease,
      transform 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    z-index: 200;
  }
  .gallery_container.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  // .new_left {
  //   @extend .left;
  // }

  // @media screen and(max-width: 1100px) {
  //   .image-gallery {
  //     .right {
  //       all: unset;
  //     }
  //     .image-gallery-content {
  //       @extend .new_left;
  //       .image-gallery-slide-wrapper {
  //         @extend .new_left;
  //       }
  //     }
  //   }
  // }
}
