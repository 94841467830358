@import "variables";

// Basic Elements:
body {
  &::-webkit-scrollbar {
    // -webkit-appearance: none; warning, deprecated.
    width: 2px;
    background-image: linear-gradient(45deg, $base-color-gray-15, $base-color);
  }
  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(
      45deg,
      $base-color-gray-15,
      $secund-base-color
    );
    border-radius: 10px;
  }
}

.AF_button {
  font-size: 1rem;
  cursor: pointer;
  border: none;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  background-color: $secund-base-color;
  color: $gray-15;

  font-family: sans-serif, Arial, Helvetica, sans-serif;
}

.AF_op0 {
  opacity: 0;
}

input,
textarea {
  border: 1px solid $gray-65;
  border-radius: 5px;
  background-color: $gray-85;
}

span {
  color: $secund-base-color;
}

::placeholder {
  color: lighten($base-color, 20);
}

// Cards
.cardsContainer {
  display: grid;
  // justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-auto-rows: max-content;
  grid-gap: 2rem;

  color: $base-color-s80;
  list-style: none;
}
.card {
  height: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 1px 1px 2px rgba($gray-35, 0.8), 0 10px 20px rgba($gray-35, 0.5);
  background: linear-gradient(
    50deg,
    lighten($gray-75, 16%) 30%,
    $base-color 100%
  );
  legend span {
    text-shadow: 1px 1px $gray-75;
  }
  .cardHeader {
    padding: 1rem;
    width: 100%;
  }
  .cardContent {
    position: relative;
    height: min-content;
    isolation: isolate;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background-color: $opct-025;
    padding: 0.5rem;

    &:before {
      content: "";

      position: absolute;
      z-index: -1;

      inset: 0;
      background: inherit;
    }
    &:before {
      filter: blur(0.75em);
    }
  }
}

.underlineWAni {
  margin: 0.5rem 1rem;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(242, 105, 68, 0.1),
    rgba(242, 105, 68, 1),
    rgba(242, 105, 68, 0.1)
  );
  // animation: 0.8s in-out forwards;
}

// @keyframes in-out {
//   0% {
//     transform: translateX(-100%);
//   }
//   45%,
//   55% {
//     /*to stay a while full width*/
//     transform: translateX(-50%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }
// For larger than tablet screen size
@media screen and (min-width: 768px) {
  body {
    &::-webkit-scrollbar {
      // -webkit-appearance: none; warning, deprecated.
      width: unset;
    }

    .AF_button {
      &:hover {
        box-shadow: 0px 1px 4px black;
      }
    }
    .underlineWAni {
      margin: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .card {
    border-radius: 0;
  }
}
.error {
  color: $error;
}

.slideLeftAni {
  opacity: 0;
  animation: slidRight 1s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    fade 0.8s ease-in forwards;
}
.slideDownAni {
  opacity: 0;
  animation: slidDown 2s ease-out, fade 0.5s ease-in forwards;
}
.fade_ani {
  opacity: 0;
  animation: fade 0.75s ease-in forwards;
}

.skew_in_ani {
  opacity: 0;

  animation: pan 1s ease-in-out, fade 1s ease-out forwards;
}

.flip_scale_forward_ani {
  -webkit-animation: flip_scale_forward_ani 1s cubic-bezier(0.5, 0, 0.5, 0.9),
    fade 1s cubic-bezier(0.5, 0, 0.5, 0.9) forwards;
  animation: flip_scale_forward_ani 1s cubic-bezier(0.5, 0, 0.5, 0.9),
    fade 1s cubic-bezier(0.5, 0, 0.5, 0.9) forwards;
}

@keyframes scale_ani {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pan {
  0% {
    transform: scaleX(0.4) rotateY(90deg) translateY(100%);
  }
  // 50% {
  //   transform: scaleX(0.3) rotateY(80deg) translateY(80%);
  // }
  // 75% {
  //   transform: scaleX(0.2) rotateY(30deg) translateY(30%);
  // }
  100% {
    transform: scaleX(1) rotateY(0deg) translateY(0);
  }
}
@keyframes slidRight {
  0% {
    left: -500px;
  }
  100% {
    left: 0px;
  }
}
@keyframes slidDown {
  0% {
    top: -300px;
  }
  100% {
    top: 0px;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flip_scale_forward_ani {
  0% {
    -webkit-transform: translateY(50%) rotateX(90deg) scale(0.1);
    transform: translateY(50%) rotateX(90deg) scale(0.1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}
@keyframes flip_scale_forward_ani {
  0% {
    -webkit-transform: translateY(50%) rotateX(90deg) scale(0.1);
    transform: translateY(50%) rotateX(90deg) scale(0.1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

// ScrollBar:
@media screen and (min-width: 1280px) {
  body {
    &::-webkit-scrollbar {
      width: 15px;
      background-image: linear-gradient(
        45deg,
        $base-color-gray-15,
        $base-color
      );
      overflow: hidden;
    }
    &::-webkit-scrollbar-thumb {
      background-image: linear-gradient(
        45deg,
        $base-color-gray-15,
        $secund-base-color
      );
      border-radius: 10px;
    }
  }
}

.dark body {
  .AF_button {
    color: $gray-15;
  }
  input,
  textarea {
    background-color: $gray-65;
  }
}
