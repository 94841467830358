@import "../../styles/scss/variables";

.QR_section {
  min-height: 100vh;

  .PageHero {
    background-color: $base-color;
  }
}

.QR_container {
  padding: 2rem;
  flex-direction: column;
  h2 {
    padding-block: 0.5rem;
  }
}

.QR_h1 {
  font-size: Min(10vw, 5rem);
  text-wrap: nowrap;
  padding-inline: 2rem;
  color: $gray-65;
}

#QR_CV {
  width: 100%;
  max-width: 400px;
  border: 1px solid black;
  border-radius: 10px;
}
