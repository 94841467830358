.App {
  height: 100%;

  .mainContainer {
    position: relative;
  }
}

// Bigger then mobile
@media screen and (min-width: 768px) {
  .App {
    .mainContainer {
      padding-top: 0px;
    }
  }
}
