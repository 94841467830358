@import "../../styles/scss/variables.scss";

.burger {
  span {
    display: block;
    width: 40px;
    height: 4px;
    margin: 8px auto;
    background: $secund-base-color;
    transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}
.burgerAni {
  /* rotate first span */
  span:first-of-type {
    transform: rotate(45deg) translate(9px, 9px);
  }
  /* hide second span */
  span:nth-of-type(2) {
    opacity: 0;
  }
  /* rotate third span */
  span:last-of-type {
    transform: rotate(-45deg) translate(8px, -8px);
  }
}

// remove burger on bigger screens.
@media screen and (min-width: 768px) {
  .burger {
    display: none;
  }
}
