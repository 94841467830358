@import "../../styles/scss/variables";

.GalleryGrid {
  padding: 1rem;
  .gallery_grid_no_images {
    width: fit-content;
    border: none;
    padding: 1.5rem;
    h1 {
      margin-top: -1rem;
    }
    legend {
      padding-left: calc(90% - 0.5rem);
      span {
        color: $base-color;
        background-color: $gray-65;
        padding: 0.5rem;
        border-radius: 50%;
        font-size: 1.5rem;
      }
    }
  }
  .gallery_container {
    width: $container-maxW;
    // for fullscreen transition ↓
    max-width: 100%;
    position: relative;
    display: flex;
    gap: 0.5rem;
    padding: 1rem 0;
    flex-wrap: wrap;
    justify-content: center;
    .gallery_image {
      display: flex;
      justify-content: center;
      transition: all 300ms ease;
      cursor: pointer;

      &:hover {
        position: relative;
        filter: opacity(0.8);
      }
    }
  }
}

@media screen and (max-width: 790px) {
  .GalleryGrid {
    padding: 1rem 0;
    .gallery_grid_no_images {
      margin: 0.5rem auto;
    }
    .gallery_container {
      flex-direction: column;
      flex-wrap: nowrap;

      .gallery_image {
        padding-block: 0.5rem;
        img {
          max-width: 100vw;
          object-fit: cover;
          overflow-y: hidden;
        }
      }
    }
  }
}
